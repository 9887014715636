import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3em 0;
  width: 100%;
  color: #aaa;
`

import React, { useState, useEffect, Fragment } from "react"
import { OutboundLink } from "gatsby-plugin-gtag"
import { API_URL } from "../utils/helpers"
import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"
import Header from "../components/Header"
import PageHdr from "../components/PageHdr"
import Footer from "../components/Footer"
import Loading from "../components/Loading"
import Alert from "../components/Alert"
import carrierCard from "../img/carrier-card.png"
import Table from "../components/content/Receipt/Table"
import Address from "../components/content/Receipt/Address"
import Notes from "../components/content/Receipt/Notes"
import ButtonLink from "../components/ButtonLink"
import { Main, MainContainer, Grid } from "../components/content/Receipt/styled"

const Receipt = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [order, setOrder] = useState({})
  const [alert, setAlert] = useState({})

  useEffect(() => {
    if (location && location.state) {
      init(location.state.transactionId) // transactionId from location.state.transactionId
    } else {
      setAlert({
        type: "error",
        text: "An error has occurred",
      })
      setLoading(false)
    }
  }, [location])

  const init = async transactionId => {
    const url = new URL(`${API_URL}/checkoutReceiptInit`)
    const params = { transactionId }
    url.search = new URLSearchParams(params)

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        setOrder({
          transactionId: json.transactionId,
          method: json.method,
          date: json.date,
          installNotes: json.installNotes,
          shippingNotes: json.shippingNotes,
          items: json.items,
          totals: json.totals,
          contact: json.contact,
          shipping: json.shipping,
          billing: json.billing,
        })
        setAlert({
          type: "success",
          text: json.text,
        })
      } else {
        setAlert({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setAlert({
        type: "error",
        text: "An error has occurred.",
      })
    }
    setLoading(false)
  }

  return (
    <Layout>
      <Helmet
        name="Order Receipt"
        description="You have successfully submitted your order, thank you for your business."
        slug="receipt"
      />
      <Header />
      <PageHdr title="Order Receipt" />
      <section>
        <Main>
          <MainContainer className="main-page">
            <article>
              {loading ? (
                <Loading />
              ) : alert.type && alert.type !== "success" ? (
                <Alert data={alert} />
              ) : (
                <Fragment>
                  <h2>Your order has been placed, thank you.</h2>
                  <p>
                    A copy of your order details has been emailed to{" "}
                    <strong>{order.contact.email}</strong> (please remember to
                    check your spam folder) or print this page for your records.
                    Please reference your order number and date in any
                    correspondence.
                  </p>

                  {order.method === "finance" && (
                    <div className="finance">
                      <img src={carrierCard} alt="Carrier Card" />
                      <p>
                        Apply for your{" "}
                        <OutboundLink
                          href="https://retailservices.wellsfargo.com/pl/0030055149"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Wells Fargo Carrier® credit card
                        </OutboundLink>{" "}
                        to complete your purchase.
                      </p>
                    </div>
                  )}

                  <table className="submitted">
                    <tbody>
                      <tr>
                        <th className="right">Order Number:</th>
                        <td>{order.transactionId}</td>
                      </tr>
                      <tr>
                        <th className="right">Date Submitted:</th>
                        <td>{order.date}</td>
                      </tr>
                    </tbody>
                  </table>

                  <Grid>
                    <Table items={order.items} totals={order.totals} />

                    <Address
                      contact={order.contact}
                      shipping={order.shipping}
                      billing={order.billing}
                    />

                    <Notes
                      install={order.installNotes}
                      shipping={order.shippingNotes}
                    />

                    <div className="btn">
                      <ButtonLink
                        to="/contact"
                        name="Questions? Send us a Message"
                      />
                    </div>
                  </Grid>
                </Fragment>
              )}
            </article>
          </MainContainer>
        </Main>
      </section>
      <Footer />
    </Layout>
  )
}

export default Receipt

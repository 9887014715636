import styled from "styled-components"

import { Table as BaseTable } from "../../../../elements/Layout/styled"

export const Table = styled(BaseTable)`
  margin-top: 15px;

  & td > span {
    font-size: 1.1rem;
  }

  & td > div {
    display: flex;
    flex-direction: column;
    text-align: left;

    & > span {
      font-weight: 600;
    }
  }

  & tfoot td {
    font-size: 0.9rem;
  }
`

import styled from "styled-components"

import {
  Main as BaseMain,
  MainContainer as BaseMainContainer,
  Grid as BaseGrid,
} from "../../../elements/Layout/styled"

export const Main = styled(BaseMain)``

export const MainContainer = styled(BaseMainContainer)`
  & > article {
    width: 100%;
  }

  & div.finance {
    border: 1px solid #ddd;
    padding: 7.5px;
    display: flex;
    align-items: center;

    & img {
      width: 125px;
    }

    & p {
      margin: 0;
      padding: 0;
      padding-left: 7.5px;
    }
  }

  & table.submitted {
    margin-top: 25px;
  }

  & table.submitted th,
  & table.submitted td {
    font-size: 0.9rem;
    padding: 7.5px 7.5px 7.5px 15px;
    border: 1px solid #ddd;
  }

  & table.submitted th.right {
    text-align: right;
  }

  & table.submitted td {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
  }
`

export const Grid = styled(BaseGrid)`
  width: 100%;
  grid-template-columns: 1fr;
  justify-items: center;

  & > div.btn {
    width: 100%;
    margin-top: 25px;
    padding: 30px 25%;
    border: 1px solid #ddd;

    @media screen and (max-width: 767px) {
      padding: 30px;
    }
  }
`

import styled from "styled-components"
import { Grid as BaseGrid } from "../../../../elements/Layout/styled"

export const Grid = styled(BaseGrid)`
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 25px;
  grid-column-gap: 15px;

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  & > p {
    margin: 0;
    margin-bottom: 7.5px;
    font-weight: 600;
    text-align: center;
    order: 0;
  }

  & > p:nth-child(2) {
    @media screen and (max-width: 767px) {
      margin-top: 15px;
      order: 2;
    }
  }

  & > p:nth-child(3) {
    @media screen and (max-width: 767px) {
      margin-top: 15px;
      order: 4;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    order: 1;

    & > span {
      font-size: 0.95rem;
      line-height: 24px;
    }
  }

  & > div.shipping {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;

    @media print {
      border-left: none;
      border-right: none;
    }

    @media screen and (max-width: 767px) {
      order: 3;
      border-left: none;
      border-right: none;
    }
  }

  & > div.billing {
    @media screen and (max-width: 767px) {
      order: 5;
    }
  }
`

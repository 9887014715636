import styled from "styled-components"

import { Grid as BaseGrid } from "../../../../elements/Layout/styled"

export const Grid = styled(BaseGrid)`
  grid-template-columns: 1fr 1fr;
  margin-top: 50px;
  grid-column-gap: 25px;

  @media screen and (max-width: 767px) {
    margin-top: 25px;
    grid-template-columns: 1fr;
  }

  & > p {
    margin: 0;
    margin-bottom: 7.5px;
    font-weight: 600;
    text-align: center;
    order: 0;
  }

  & > p:nth-child(2) {
    @media screen and (max-width: 767px) {
      margin-top: 25px;
      order: 2;
    }
  }

  & > div {
    padding: 15px;
    border: 1px solid #ddd;
    order: 1;
  }

  & > div.shipping {
    @media screen and (max-width: 767px) {
      order: 3;
    }
  }
`

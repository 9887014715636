import React from "react"
import { Container } from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons"

const Loading = () => {
  return (
    <Container>
      <FontAwesomeIcon icon={faSpinnerThird} spin size="4x" />
    </Container>
  )
}

export default Loading

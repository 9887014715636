import React from "react"
import { Grid } from "./styled"

const Address = ({ contact, shipping, billing }) => {
  return (
    <Grid>
      <p>Contact</p>
      <p>Shipping/Install</p>
      <p>Billing</p>
      <div>
        <span>{`${contact.fname} ${contact.lname}`}</span>
        <span>{contact.email}</span>
        <span>{contact.phone}</span>
      </div>

      <div className="shipping">
        <span>{`${shipping.address}`}</span>
        {shipping.address2 && <span>{`${shipping.address2}`}</span>}
        <span>{`${shipping.city}, ${shipping.st} ${shipping.zip}`}</span>
      </div>

      <div className="billing">
        <span>{billing.address}</span>
        {billing.address2 && <span>{`${billing.address2}`}</span>}
        <span>{`${billing.city}, ${billing.st} ${billing.zip}`}</span>
      </div>
    </Grid>
  )
}

export default Address

import React from "react"
import { Grid } from "./styled"

const Notes = ({ install, shipping }) => {
  return (
    <Grid>
      <p>Installation Notes</p>
      <p>Shipping Notes</p>
      <div>
        <p>{install}</p>
      </div>

      <div className="shipping">
        <p>{shipping}</p>
      </div>
    </Grid>
  )
}

export default Notes
